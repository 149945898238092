import { ExperienceList } from './interface';

export const experience: ExperienceList = [
  {
    kind: 'engineering',
    start: [2015, 9],
    end: [2019, 6],
    institute: 'Publicity Department, Northeastern University',
    location: 'Shenyang, Liaoning, China',
    position: 'Student Helper - Web Development',
  },
  {
    kind: 'research',
    start: [2018, 7],
    end: [2018, 8],
    institute: 'North Carolina State University',
    location: 'Raleigh, North Carolina, USA',
    position: 'Undergraduate Summer Research Student',
    tags: ['Complex Network Analysis'],
  },
  {
    kind: 'engineering',
    start: [2018, 9],
    end: [2019, 1],
    institute: 'Shenyang Wuzhi Technology Co., Ltd.',
    location: 'Shenyang, Liaoning, China',
    position: 'Application Backend Developer',
  },
  {
    kind: 'research',
    start: [2019, 3],
    end: [2019, 6],
    institute: 'The Hong Kong University of Science and Technology',
    location: 'Hong Kong, China',
    position: 'Undergraduate Research Intern',
    tags: ['Program Analysis'],
  },
  {
    kind: 'engineering',
    start: [2020, 10],
    end: [2021, 5],
    institute: 'Hong Kong Applied Science and Technology Research Institute',
    location: 'Hong Kong, China',
    position: 'Ethereum Application Developer',
  },
  {
    kind: 'research',
    start: [2023, 2],
    end: [2024, 12],
    institute: 'Purdue University',
    location: 'West Lafayette, Indiana, USA',
    position: 'Research Scholar',
    tags: ['Program Analysis', 'Blockchain Security'],
  },
  {
    kind: 'research',
    start: [2024, 4],
    institute: 'MegaETH',
    location: 'Hong Kong, China',
    position: 'Research Scientist',
  },
];
